const state = {
  renewalDate: ""
};
const getters = {
  getRenewalDate: state => {
    return state.renewalDate;
  }
};

const actions = {
  setRenewalDate({ commit }, value) {
    commit("setRenewalDate", value);
  }
};

const mutations = {
  setRenewalDate(state, value) {
    state.renewalDate = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
