<template>
  <div>
    <v-alert
      v-model="alertWarning"
      dismissible
      type="warning"
      color="primary"
      class="alert-warning alert-overlay"
      :class="{ rtl: $vuetify.rtl }"
    >
      {{
        $t("Your subscription renewal is due on") +
          ` ${renewalDate}, ` +
          $t("Please contact")
      }}
      <a href="mailto:csd@ottu.com" class="alert-text">csd@ottu.com</a>
      {{ $t("to renew your subscription.") }}
    </v-alert>
    <v-alert
      v-model="alertError"
      dismissible
      type="error"
      color="#ff0000"
      class="alert-warning alert-overlay"
      :class="{ rtl: $vuetify.rtl }"
    >
      {{
        $t("Immediate action is required to renew your subscription before") +
          ` ${renewalDate}, ` +
          $t("contact")
      }}
      <a href="mailto:csd@ottu.com" class="alert-text">csd@ottu.com</a>
      {{ $t("to avoid service interruption.") }}
    </v-alert>
  </div>
</template>
<script>
import moment from "moment";

import store from "../store";

export default {
  name: "Alert",
  data() {
    return {
      alertError: false,
      alertWarning: false
    };
  },
  computed: {
    renewalDate() {
      return store.getters["dashboard/getRenewalDate"];
    }
  },
  watch: {
    renewalDate() {
      this.checkRenewalDate();
    }
  },
  mounted() {
    this.checkRenewalDate();
  },
  methods: {
    checkRenewalDate() {
      if (this.renewalDate) {
        const currentDate = moment();
        const renewalDate = moment(this.renewalDate, "DD/MM/YYYY");
        const daysDiff = renewalDate.diff(currentDate, "days");
        if (daysDiff < 30) {
          this.alertError = true;
        } else if (daysDiff < 60) {
          this.alertWarning = true;
        }
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-alert__dismissible {
  align-self: center;
}
</style>

<style>
.alert-warning {
  margin-right: 17px !important;
  box-shadow: none !important;
  border-color: transparent !important;
  padding: 4px !important;
  border-radius: 10px !important;
  border-width: 0px !important;
}

.alert-warning .theme--light.v-icon,
.alert-warning .v-alert__icon.v-icon {
  color: #fff !important;
  margin: 0 !important;
}
.alert-overlay {
  position: fixed !important;
  top: 90px;
  right: 1%;
  z-index: 9999 !important;
  max-width: 40%;
  text-align: center;
}
@media (max-width: 768px) {
  .alert-overlay {
    max-width: 80%;
    min-width: 70%;
    top: 90px;
    right: 50% !important;
    transform: translate(50%, 0%);
    margin-right: 0px !important;
  }
}
.rtl.alert-overlay {
  left: 1%;
  right: unset;
}
.rtl .alert-warning {
  margin-right: 0;
  margin-left: 17px;
}
.alert-text {
  color: white !important;
  text-decoration: underline;
}
</style>
